.App {
  text-align: center;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
}

#login-page, #verify-page, #register-page {
  background: linear-gradient(180deg, #6B50A1 3.49%, #4C3691 27.36%);
  height: 100vh;
  min-height: fit-content;
}

.form-submit-btn {
  height: 46px;
  font-weight: 700;
  width: 100% !important;
  background: linear-gradient(180deg, #BE281B 30%, #73222E 80%);
  color: white;
  border-radius: 50px;
  border: 5px #FCF5BC solid;
}

.form-submit-btn.purple {
  background: #513a93;  
}

.form-submit-btn.grey {
  height: 46px;
  font-weight: 700;
  width: 100% !important;
  background: #dfd9cf !important;
  color: #7e7e7e;
  border-radius: 20px;
}

.login-form {
  width: 85%;
  margin: 0 auto;
}

.form-input, .form-input:hover, .form-input:focus, .form-input:focus-within {
  background-color: transparent;
  border-radius: 24px;
  padding: 12px;
  color: #fecc59;
  margin-top: 10px;
}

.form-input::placeholder {
  color: #bcbcbc;
}

.success-btn {
  background-color: #02bc7d;
  border-radius: 24px;
  height: 40px;
  width: 90%;
  color: white;
  font-weight: 700;
}

.upload-receipt-input-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background:#0e1d49;
  color: white;
  border-radius: 10px;
  height: 50vh;
  margin: 20px auto;
  width: 90%;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13);
  position: relative;
  font-weight: 700;
}

.reward-card {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.copied {
  position: fixed;
  top: 50%;
  right: 20%;
  background-color: rgba(18, 16, 16, 0.85);
  /* opacity: .7; */
  width: 55%;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
}

/* ANTD STYLING */
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background-color: transparent !important;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border-radius: 50%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fecc59;
  border-color: #fecc59;
  color: #332e7c !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
 border: 2px solid #332e7c; 
 border-top: 0;
 border-inline-start: 0;
}

.web-bg {
  background: linear-gradient(180deg,#3c2874 20%, #583e8d 50%, #593f8e 25%);
}

.home-bg {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.main-button {
  background: linear-gradient(180deg, #BE281B 30%, #73222E 80%);
  border: 4px solid rgb(250, 247, 200);
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 70%;
  margin: 0 auto;
}

.main-button-disabled {
  background: #bdbdbd;
  border: 4px solid #bdbdbd;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.main-button:disabled {
  background: #bdbdbd;
  border: 4px solid #bdbdbd;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.8;
}

.main-button:focus {
  outline: none; 
  box-shadow: none;
}

.main-button-container {
  width: 45%;
  max-width: 250px;
  text-decoration: none;
}

.main-content {
  flex: 1 1;
}

.disclaimer {
  color: black;
  font-size: 8px;
  text-align: left;
  max-width: 70%;
  margin: 0 5%;
  line-height: 1;
  bottom: 8%;
  position: absolute;
};
